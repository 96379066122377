<template>
  <div class="about container-fluid min-vh-100 whitebg">
    <vue-headful
      title="Install-HC - A propos de nous"
      description="Présentation du site consacré aux Installations Home-Cinema de particuliers"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC/>
        </div> 
        <Menu/>
      </nav>
    </div>
    <div class="listeMembres">
      <h1>A propos de... <router-link to="/">Install-HC</router-link></h1>            
      <p class="shadow">
        <router-link to="/">Install-HC</router-link> est un site gratuit permettant à ces membres de partager leur passion en publiant leur Installation Home Cinéma.<br/>    
        Qu'elles soient dédiées ou non, débutantes ou confirmées, toutes les Installations Home-Cinema ont leur place sur le site <router-link to="/">Install-HC</router-link>.<br/>
        Chez <router-link to="/">Install-HC</router-link>, nous voyons notre passion commune qu'est le Home Cinéma comme un loisir abordable et ouvert. Vous pourrez largement vous inspirer d'autres réalisations présentées sur le site <router-link to="/">Install-HC</router-link> !<br/>  
        Alors, n'hésitez pas à publier votre Installation Home Cinéma et à venir commenter et échanger avec d'autres passionnés sur le site <router-link to="/">Install-HC</router-link> !<br/>    
        Afin de publier ou commenter sur le site Install-hc, il est nécessaire de <router-link to="/createAccount">créer un compte utilisateur</router-link>.<br/>
        En cas de souci, n'hésitez pas à <router-link to="/contactus">contacter un des administrateurs</router-link> d'<router-link to="/">Install-HC</router-link> qui se fera un plaisir de vous assister !
      </p>
    </div> 
    <Pub/>   
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
export default { 
  name: 'about',
  components: {
    Menu,
    InstallHC
  },
  data() {         
    return {
      json: null, 
      windowHeight: 0,
      txt: '',
      actualSize: 0,
      target: ''
    }    
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },    
  mounted() {    
    this.actualSize = window.innerHeight    
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowHeight = window.innerHeight;
        this.actualSize = (window.innerHeight-117)
      });
    })   
  }
}

</script>

<style scoped>
.debug {
  display: none;
}

.about {
  margin:0 auto;  
  width:100%;    
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

a:hover {
  text-decoration: none;
}

p {
  margin:20px;
  padding:20px;
}

</style>